import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import profilepic1 from './profilepic1.png';
import profilepic2 from './profilepic2.png';

import css from './ReviewSection.module.css';
import { Button, NamedLink, ReviewRating } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

export const ReviewSectionComponent = props => {
  return (
    <div className={css.root}>
      <p className={css.header}>
        <FormattedMessage id="ReviewSection.title" />
      </p>
      <div className={css.sectionWrapper}>
        <div className={css.card}>
          <div className={css.titleWrapper}>
            <svg
              className={css.icons}
              fill="none"
              height="18"
              viewBox="0 0 20 18"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="m7.87354 0h-7.87354v7.80029h4.50439v1.31836c0 1.93015-.16689 2.39485-.50067 3.32445l-.01202.0335c-.5127 1.5625-1.84326 2.3925-3.9917 2.4902v2.8564c5.24902 0 7.87354-2.2836 7.87354-8.85103zm11.48146 0h-7.8736v7.80029h4.5044v1.31836c0 1.93015-.1669 2.39485-.5006 3.32445l-.0121.0335c-.5127 1.5625-1.8432 2.3925-3.9917 2.4902v2.8564c5.2491 0 7.8736-2.2836 7.8736-8.85103z"
                fill="#1ead52"
                fill-rule="evenodd"
              />
            </svg>
            <div className={css.cardRight}>
              <span>
                Jestem bardzo zadowolona z pomocy, byłam w ciągłym kontakcie z Patrycją, zdawała mi
                na bieżąco relacje.
              </span>
              <span className={css.author}> - Katarzyna, Warszawa</span>
              <ReviewRating rating={5} />
            </div>
          </div>
          <div className={css.profilePicWrapper}>
            <img className={css.profilePic} src={profilepic1} />
          </div>
        </div>
        <div className={css.card}>
          <div className={css.titleWrapper}>
            <svg
              className={css.icons}
              fill="none"
              height="18"
              viewBox="0 0 20 18"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="m7.87354 0h-7.87354v7.80029h4.50439v1.31836c0 1.93015-.16689 2.39485-.50067 3.32445l-.01202.0335c-.5127 1.5625-1.84326 2.3925-3.9917 2.4902v2.8564c5.24902 0 7.87354-2.2836 7.87354-8.85103zm11.48146 0h-7.8736v7.80029h4.5044v1.31836c0 1.93015-.1669 2.39485-.5006 3.32445l-.0121.0335c-.5127 1.5625-1.8432 2.3925-3.9917 2.4902v2.8564c5.2491 0 7.8736-2.2836 7.8736-8.85103z"
                fill="#1ead52"
                fill-rule="evenodd"
              />
            </svg>
            <div className={css.cardRight}>
              <span>
                Ewelina ze wszystkim sobie poradziła. Kenzo był zaopiekowany i wrócił do domu
                spokojny i zadowolony;) Bardzo polecam!"{' '}
              </span>
              <span className={css.author}> - Joanna, Wrocław</span>
              <ReviewRating rating={5} />
              <div className={css.profilePicWrapper}>
                <img className={css.profilePic} src={profilepic2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className={css.heroButton}
        onClick={() => {
          props.history.push('/s');
        }}
      >
        <FormattedMessage id="SectionHero.browseButton" />
      </Button>
    </div>
  );
};

const { bool, object } = PropTypes;

ReviewSectionComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ReviewSection = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ReviewSectionComponent);

export default ReviewSection;
